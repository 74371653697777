<template>
  <form class="p-4 container" @submit.prevent="handleSubmit">
    <h4>Popup Configuration</h4>

    <div class="row">
      <select-input
        :validator="$v.popupDetails.typeId"
        name="type"
        class="col"
        label="Type"
        v-model="popupDetails.typeId"
        :items="popupTypes"
      />
      <text-input
        :validator="$v.popupDetails.name"
        class="col"
        name="name"
        label="Name"
        v-model="popupDetails.name"
      />

      <tag-input
        :maxDisplayedTags="1"
        class="col"
        label="Events"
        displayExpr="displayName"
        :items="eventOptions"
        v-model="popupDetails.triggerEventIds"
        name="eventOptions"
      />
    </div>
    <template v-if="isImagePopup">
      <div class="row">
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          name="imageTypeIds"
          label="Image Types"
          :items="imageTypes"
          v-model="popupDetails.imageTypeIds"
          :validator="$v.popupDetails.imageTypeIds"
        />
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Roles"
          displayExpr="displayName"
          :dataSource="roleOptions"
          v-model="popupDetails.roleIds"
          name="roleIds"
          :validator="$v.popupDetails.roleIds"
        />
      </div>
    </template>
    <template v-else>
      <div class="row">
        <text-area-input
          label="Text"
          class="col"
          name="displayText"
          :validator="$v.popupDetails.displayText"
          v-model="popupDetails.displayText"
        />
      </div>
      <div class="row">
        <select-input
          class="col"
          label="Cancel Button"
          name="hasCancelButton"
          :items="booleanOptions"
          v-model="popupDetails.hasCancelButton"
        />
        <select-input
          class="col"
          label="Requires Initials"
          name="requireInitials"
          :items="booleanOptions"
          v-model="popupDetails.requireInitials"
        />
        <select-input
          class="col"
          label="Trigger On Patient History"
          name="triggerOnPatientHistory"
          :items="booleanOptions"
          v-model="popupDetails.triggerOnPatientHistory"
        />
      </div>
      <div class="row">
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Providers"
          :searchExpr="providerSearchExpr"
          :displayExpr="providerDisplay"
          valueExpr="id"
          :dataSource="providerOptions"
          v-model="popupDetails.primaryProviderIds"
          name="primaryProviderIds"
          :enableSelectPopup="true"
          :selectedItems="selectedProviders"
          @closeSelectPopup="closeSelectProvidersPopup"
        />
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Locations"
          displayExpr="name"
          :dataSource="locationOptions"
          v-model="popupDetails.primaryLocationIds"
          name="primaryLocationIds"
          :enableSelectPopup="true"
          :selectedItems="selectedLocations"
          @closeSelectPopup="closeSelectLocationsPopup"
        />
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Insurances"
          displayExpr="code"
          :dataSource="insuranceOptions"
          v-model="popupDetails.primaryInsuranceIds"
          name="primaryInsuranceIds"
        />
      </div>
      <div class="row">
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Transaction Codes"
          displayExpr="code"
          :dataSource="transactionCodeOptions"
          v-model="popupDetails.billingTransactionCodeIds"
          name="billingTransactionCodeIds"
        />
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Macros"
          valueExpr="macroId"
          displayExpr="macroName"
          :dataSource="macroOptions"
          v-model="popupDetails.macroIds"
          name="macroIds"
        />
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Billing Cycles"
          displayExpr="displayName"
          :dataSource="billingCycleOptions"
          v-model="popupDetails.billingCycleIds"
          name="billingCycleIds"
        />
      </div>
      <div class="row">
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Priorities"
          displayExpr="displayName"
          :items="priorityOptions"
          v-model="popupDetails.casePriorityIds"
          name="casePriorityIds"
        />

        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Tags"
          displayExpr="displayName"
          :dataSource="tagOptions"
          v-model="popupDetails.tagIds"
          name="tagIds"
        />
        <tag-input
          :maxDisplayedTags="1"
          class="col"
          label="Roles"
          displayExpr="displayName"
          :dataSource="roleOptions"
          v-model="popupDetails.roleIds"
          name="roleIds"
          :validator="$v.popupDetails.roleIds"
        />
      </div>
    </template>
    <div class="row"></div>
    <div class="row justify-content-end mt-2 px-2">
      <button type="submit" class="btn btn-primary">Save</button>
    </div>
  </form>
</template>

<script>
import { booleanLookup, dateRangeFilter } from "@/modules/helpers";
import SelectInput from "./common/SelectInput.vue";
import TextAreaInput from "./TextAreaInput.vue";
import TextInput from "./common/TextInput.vue";
import { required, maxLength, minLength, helpers } from "vuelidate/lib/validators";
import TagInput from "./common/TagInput.vue";
import {
  BillingApi,
  DropdownApi,
  ImagesApi,
  InsuranceApi,
  MacrosApi,
  PopupApi,
  ProvidersApi
} from "@/services";
import DataSource from "devextreme/data/data_source";
import { enumToDropDown, PopupTypesEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";
import { mapState } from "vuex";
import { sortBy } from "lodash";

export default {
  components: { SelectInput, TextAreaInput, TextInput, TagInput },
  name: "PopupForm",
  props: ["popupId"],
  data() {
    return {
      providerSearchExpr: ["firstName", "lastName"],
      booleanOptions: booleanLookup.dataSource,
      popupTypes: enumToDropDown(PopupTypesEnum),
      popupDetails: {
        name: "",
        type: "",
        displayText: "",
        hasCancelButton: false,
        requireInitials: false,
        triggerOnPatientHistory: false,
        primaryProviderIds: [],
        primaryLocationIds: [],
        imageTypeIds: [],
        insuranceIds: [],
        macroIds: [],
        billingTransactionCodeIds: [],
        billingCycleIds: [],
        casePriorityIds: [],
        roleIds: [],
        triggerEventIds: [],
        tagIds: []
      },
      imageTypes: [],
      providerOptions: new DataSource({
        store: ProvidersApi.searchProviders,
        sort: "lastName"
      }),

      locationOptions: new DataSource({ store: ProvidersApi.searchLocations, sort: "name" }),
      insuranceOptions: new DataSource({
        store: InsuranceApi.searchStore,
        filter: dateRangeFilter(),
        sort: "code"
      }),
      transactionCodeOptions: new DataSource({
        store: BillingApi.searchStore,
        filter: dateRangeFilter(),
        sort: "code"
      }),
      macroOptions: new DataSource({
        store: MacrosApi.searchStore,
        filer: [
          [["macroType", 3], "or", ["macroType", 0]],
          "and",
          dateRangeFilter("effectiveOn", "expiresOn")
        ], //Only Results & Protocol Macros
        sort: "macroName"
      }),
      billingCycleOptions: [],
      tagOptions: new DataSource({ store: DropdownApi.searchTags, filter: ["settingType", "T"] }),
      roleOptions: new DataSource({ store: DropdownApi.searchRoles, sort: "displayName" }),
      eventOptions: [
        { displayName: "Accession", id: 1 },
        { displayName: "Case View", id: 2 },
        { displayName: "Case Save", id: 3 },
        { displayName: "Sign Out", id: 4 },
        { displayName: "Specimen", id: 5 }
      ],
      selectedProviders: [],
      providerSelectOptions: [],
      selectedLocations: [],
      locationSelectOptions: []
    };
  },
  created() {
    DropdownApi.getBillingCycles().then(res => {
      this.billingCycleOptions = new DataSource({
        store: res || [],
        sort: ["displayName"]
      });
    });
    ImagesApi.getImageTypes().then(res => {
      this.imageTypes = res;
    });
    this.$store.dispatch("dropdowns/getPriorities");
    if (this.popupId) {
      PopupApi.searchStore.byKey(this.popupId).then(res => {
        this.popupDetails = res;
      });
    }
    ProvidersApi.getProviders(this.currentLab).then(({ data }) => {
      this.providerSelectOptions = data;
      this.getSelectedProviders();
    });
    ProvidersApi.getLocations(this.currentLab).then(({ data }) => {
      this.locationSelectOptions = data;
      this.getSelectedLocations();
    });
  },
  validations() {
    return {
      popupDetails: {
        name: {
          required,
          maxLength: maxLength(101)
        },
        imageTypeIds: {
          required: value => (this.isImagePopup ? helpers.req(value) : true)
        },
        typeId: {
          required
        },
        displayText: {
          required: value => (!this.isImagePopup ? helpers.req(value) : true),
          maxLength: maxLength(4001)
        },
        roleIds: {
          minLength: minLength(1),
          required
        }
      }
    };
  },
  watch: {
    "popupDetails.primaryProviderIds": {
      immediate: true,
      handler(nv) {
        if (!nv) {
          this.selectedProviders = [];
        } else {
          this.$nextTick(() => {
            this.getSelectedProviders();
          });
        }
      }
    },
    "popupDetails.primaryLocationIds": {
      immediate: true,
      handler(nv) {
        if (!nv) {
          this.selectedLocations = [];
        } else {
          this.$nextTick(() => {
            this.getSelectedLocations();
          });
        }
      }
    }
  },
  computed: {
    ...mapState({
      priorityOptions: state => state.dropdowns.priorities,
      currentLab: state => state.currentLab
    }),
    isImagePopup() {
      return this.popupDetails?.typeId === PopupTypesEnum.Image;
    },
    imageTypeOptions() {
      return new DataSource({ store: this.imageTypes, sort: "displayName" });
    }
  },
  methods: {
    providerDisplay: ({ firstName, lastName }) => lastName + ", " + firstName,
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please check your input.", "warning");
        return;
      }
      try {
        if (this.popupId) {
          await PopupApi.updatePopup(this.popupDetails);
        } else {
          await PopupApi.insertPopup(this.popupDetails);
        }
        this.$emit("submit");
      } catch (error) {
        handleErrors(error);
      }
    },
    closeSelectProvidersPopup() {
      this.selectedProviders = [];
    },
    closeSelectLocationPopup() {
      this.selectedLocations = [];
    },
    getSelectedProviders() {
      this.selectedProviders = sortBy(
        this.popupDetails.primaryProviderIds.map(providerId => {
          return this.providerSelectOptions.find(e => e.id === providerId);
        }),
        "displayName"
      );
    },
    getSelectedLocations() {
      this.selectedLocations = sortBy(
        this.popupDetails.primaryLocationIds.map(locationId => {
          const location = this.locationSelectOptions.find(e => e.id === locationId);
          return { id: location.id, displayName: location.name };
        }),
        "displayName"
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
