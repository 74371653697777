<template>
  <container class="p-1">
    <dx-grid-with-search
      title="Popups"
      :columns="columns"
      @initialized="initializeGrid"
      :dataSource="dataSource"
      :toolbar="toolbar"
    >
      <template v-slot:extraActions>
        <icon-button icon="plus" class="btn-outline-primary" @click="handleAdd"> Add </icon-button>
      </template>
      <template v-slot:actions="{ data: { key } }">
        <div class="px-1">
          <icon-button
            class="text-primary pointer mr-2 p-0"
            v-tooltip.right="'Edit Popup.'"
            @click="handleEdit(key)"
            icon="pen-alt"
          />
          <icon-button
            v-tooltip.right="'Delete Popup.'"
            @click="handleDelete(key)"
            class="text-danger pointer p-0"
            icon="trash-alt"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isModalOpen" @close="isModalOpen = false">
      <popup-form @submit="handleSubmit" :popupId="popupId" />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import Modal from "@/components/common/Modal.vue";
import IconButton from "@/components/common/IconButton.vue";
import PopupForm from "@/components/PopupForm.vue";
import Container from "@/components/common/Container.vue";
import { PopupApi } from "@/services";
import { booleanLookup } from "@/modules/helpers";
export default {
  components: { DxGridWithSearch, Modal, IconButton, PopupForm, Container },
  name: "PhysicianPopups",
  metaInfo: {
    title: "Popups",
    titleTemplate: "IntelliPath - %s"
  },
  data() {
    return {
      labPopups: [],
      grid: {},
      isModalOpen: false,
      dataSource: PopupApi.searchStore,
      popupId: null
    };
  },
  computed: {
    columns() {
      return [
        {
          dataField: "name",
          dataType: "string"
        },
        {
          dataField: "displayText",
          dataType: "string"
        },
        {
          dataField: "triggerOnPatientHistory",
          dataType: "boolean",
          lookup: booleanLookup
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            template: "extraActions",
            locateInMenu: "auto"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    }
  },
  methods: {
    initializeGrid({ component }) {
      this.grid = component;
    },
    handleAdd() {
      this.isModalOpen = true;
      this.popupId = null;
    },
    handleEdit(key) {
      this.popupId = key;
      this.isModalOpen = true;
    },
    async handleDelete(key) {
      await PopupApi.removePopup(key);
      this.grid.refresh(true);
    },
    handleSubmit() {
      this.grid.refresh(true);
      this.isModalOpen = false;
      this.popupId = null;
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
